<template>
  <card>
    <template v-slot:headerTitle>
      <span class="card-title my-2 h4"><b>LISTA DE CUENTAS</b></span>
    </template>
    <div class="text-right mt-4 responsive-xl-md">
      <div class="d-flex justify-content-end">
        <vs-button icon animation-type="vertical"
                   success class="mx-1 text-right"
                   @click="$router.push('NuevaCuenta')">
          <i class="fas fa-plus px-1"></i>
          Agregar Cuenta
          <template #animate>
            <i class="fas fa-plus"></i>
          </template>
        </vs-button>
      </div>
    </div>
    <div class="table-responsive px-4 mt-3">
      <table id="datatable_cuentas" class="table table-bordered table-responsive-md table-striped text-center">
        <thead>
        <tr>
          <th>
            <div class="dataColumn">
              <span>Cuenta</span>
              <span>Numero</span>
            </div>
          </th>
          <th>
            <div class="dataColumn">
              <span>Banco</span>
              <span>Numero del Banco</span>
            </div>
          </th>
          <th>Saldo actual</th>
          <th style="width: 50px">Acciones</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in ListAccounts" :key="item.id">
          <td>
            <div class="dataColumn">
              <span>{{ item.name }}</span>
              <span>{{ item.number }}</span>
            </div>
          </td>
          <td>
            <div class="dataColumn">
              <span>{{ item.banco }}</span>
              <span>{{ item.numberbanco }}</span>
            </div>
          </td>
          <td>
            {{ item.saldo }}
          </td>
          <td>
            <div style="display: flex;">
              <vs-button danger @click="confirmDelete(item.id)"><i class="fas fa-trash"/></vs-button>
              <vs-button warn @click="$router.push('EditarCuenta/'+item.id)"><i class="fas fa-pencil" /></vs-button>
              <vs-button success @click.prevent="$router.push('informacionCuenta/'+item.id)"><i class="fas fa-eye" /></vs-button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </card>
</template>

<script>
import {core} from "@/config/pluginInit";
import Swal from "sweetalert2";

export default {
  name: 'ListAccounts',
  data() {
    return {
      idPersona: null,
      allCheck: false,
      selected: [],
      ListAccounts: [],
      filters: {
        name: '',
        banco: '',
        saldo: ''
      }
    };
  },
  async mounted() {
    core.index();
    this.idPersona = JSON.parse(localStorage.getItem("credenciales")).personaId;
    await this.getListAccounts();
    window.$("#datatable_cuentas").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
  },
  methods: {
    async confirmDelete(idProducto) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_delete", {
            path: "CONTAccount/EliminarCuenta/" + idProducto+"/usuario/"+this.idPersona,
          });
          await this.getListAccounts();
          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getListAccounts() {
      try {

        let res = await this.$store.dispatch("hl_get", {
          path: "CONTAccount/ObtenerCuentasEmpresa/"+this.idPersona,
        });
        if (res["respuesta"]) {
          this.ListAccounts = res["data"]
        } else {
          this.ListAccounts = [];
        }
      } catch (error) {
        this.ListAccounts = [];
        console.log("err", error);
      }
    },
  }
};
</script>

<style scoped>

.dataColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
}

.dataColumn :nth-child(2) {
  color: gray;
  font-size: 12px;
}
</style>
